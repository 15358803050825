import React from "react";
import SectionMax from "../../components/section-max/section-max";
import SectionHeader from "./sections/section-header/section-header";
import SectionInfo from "./sections/section-info/section-info";
import SectionInfoStep from "./sections/section-info-step/section-info-step";
import StepVideo from "./sections/step-video/step-video";
import SectionOtherInfo from "./sections/section-other-info/section-other-info";
import SectionFunctionality from "./sections/section-functionality/section-functionality";
import SectionNote from "./sections/section-note/section-note";
import SectionFaq from "./sections/section-faq/section-faq";
import ContactUs from "./sections/contact-us/contact-us";
import MainPageLayout from "../../components/mainpage-layout/mainpage-layout";

const MainPage: React.FC = () => {
  return (
    <MainPageLayout>
      <SectionMax>
        <SectionHeader />
        <SectionInfoStep />
        <SectionInfo />
        <StepVideo />
        <SectionOtherInfo />
        <SectionFunctionality />
        <SectionNote />
        <SectionFaq />
        <ContactUs />
      </SectionMax>
    </MainPageLayout>
  );
};

export default MainPage;
