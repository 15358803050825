import styled from "styled-components";
import { breakpoints } from "../../../../constants/breakpoints";

export const ContainterStepVideo = styled.div``;

export const ContainerStep = styled.div`
  grid-template-columns: repeat(4, 1fr);
  padding: 60px 0;
  grid-gap: 20px;
  display: grid;

  ${breakpoints.phoneL} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const ItemStep = styled.div`
  position: relative;
  height: 200px;
  margin: auto;
  width: 200px;

  > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  ${breakpoints.tabletL} {
    height: 160px;
    width: 160px;
  }
  ${breakpoints.tabletM} {
    height: 120px;
    width: 120px;
  }
  ${breakpoints.phoneL} {
    height: 160px;
    width: 160px;
  }

  ${breakpoints.phoneM} {
    height: 120px;
    width: 120px;
  }
`;

export const TextTitle = styled.div`
  background: #ffffffba;
  position: absolute;
  width: fit-content;
  padding: 2px 15px;
  border-radius: 20px;
  margin: auto;
  top: 25px;
  right: 0;
  left: 0;

  > p {
    color: #b92164;
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    margin: 0;
    padding: 0;
    line-height: 1.1;

    > p {
      color: #a27dea;
      margin: 0;
      padding: 0;
    }
  }
`;

export const ContainerVideo = styled.div`
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
  width: 100%;

  > div {
    width: 100% !important;
    height: 600px !important;

    ${breakpoints.tabletL} {
      height: 400px !important;
    }

    ${breakpoints.tabletM} {
      height: 300px !important;
    }

    ${breakpoints.phoneL} {
      height: 200px !important;
    }
  }
`;
