import React from "react";
import ItemFaq from "./item-faq/item-faq";
import { Accordion } from "react-accordion-ts";
import { ContainerAccordion } from "./section-faq-styles";
import "react-accordion-ts/src/panel.css";
import TitleFaq from "./title-faq/title-faq";
import { listItems } from "./data-faq";
import { useAppSelector } from "../../../../app/hooks";
import { getIdViewIcon } from "../../../../core/store/app-store/appSlice";
import FrontIMG from "../../../../assets/img/background_front.svg";
import BackIMG from "../../../../assets/img/background_back.svg";

export interface AccordionItem {
  id: number;
  open: boolean;
  title: string;
  description: string;
}

const SectionFaq: React.FC = () => {
  const idViewIcon = useAppSelector(getIdViewIcon);

  const items = (listItems || []).map(({ id, open, title, description }) => ({
    open,
    title: <TitleFaq title={title} id={id} />,
    content: <ItemFaq description={description} />,
  }));

  return (
    <ContainerAccordion
      id="preguntas-frecuentes"
      idIcon={idViewIcon}
      imgFront={FrontIMG}
      imgBack={BackIMG}
    >
      <Accordion items={items} duration={300} multiple={false} />
    </ContainerAccordion>
  );
};

export default SectionFaq;
