import { styled } from "styled-components";
import { customPalette } from "../../config/theme/theme";
import { breakpoints } from "../../constants/breakpoints";

export const ContainerFixedNav = styled.div`
  background: linear-gradient(
    90deg,
    rgba(238, 102, 77, 1) 0%,
    rgba(212, 69, 233, 1) 100%
  );
  // padding: 20px;
  width: 100%;

  ${breakpoints.tabletS} {
    height: 120px;
  }

  ${breakpoints.phoneL} {
    height: 110px;
  }
`;

export const customStylesSection = `
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 15px;
`;

export const ContainerLogo = styled.div`
  width: 100%;

  > img {
    ${breakpoints.tabletL} {
      height: 60px;
    }
    ${breakpoints.tabletM} {
      height: 70px;
    }
    ${breakpoints.phoneL} {
      max-height: 60px;
    }
  }
`;
export const ContainerNavLinks = styled.div`
  justify-content: space-between;
  align-items: top;
  display: flex;
  width: 100%;

  ${breakpoints.tabletM} {
    display: none;
  }
`;

export const ContainerBar = styled.div`
  cursor: pointer;
  display: none;

  > svg {
    max-width: 26px;
    color: white;
    width: 100%;
  }

  ${breakpoints.tabletM} {
    grid-column: 16 / span 1;
    place-items: center;
    display: grid;
  }
`;

export const BottomNavbarMobile = styled.div<{ isActive: boolean }>`
  background: ${customPalette.primaryColor};
  position: absolute;
  padding: 2rem 0 1rem;
  transition: 0.5s;
  width: 100%;
  z-index: 1;
  left: 0;

  height: ${(p) => (p.isActive ? "fit-content" : "0px")};
  transform: ${(p) => (p.isActive ? "translateY(0%)" : "translateY(-150%)")};

  ${(p) =>
    p.isActive
      ? ""
      : `
      display: none;
  `}
`;

export const ContainerGridNav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;
