import styled from "styled-components";
import { breakpoints } from "../../../../constants/breakpoints";

export const ContainerHeader = styled.div`
  flex-direction: row;
  column-gap: 40px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  width: 100%;
  padding: 40px 0 60px;

  ${breakpoints.mediaLaptopBetween} {
    flex-direction: row;
  }
  ${breakpoints.tabletM} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 40px;
  }
  ${breakpoints.phoneL} {
    row-gap: 24px;
  }
`;

export const ContainerMainTitle = styled.div`
  position: relative;
  padding: 30px 0;
`;

export const RectImg = styled.div`
  position: relative;
  background: #c6e7ec;
  width: 150px;
  height: 150px;
  border-radius: 20px;
  transform: rotate(50deg);
  z-index: 0;

  ${breakpoints.mediaTabletBetween} {
    width: 120px;
    height: 120px;
  }

  ${breakpoints.mediaLaptopBetween} {
    width: 140px;
    height: 140px;
  }

  ${breakpoints.phoneL} {
    transform: rotate(50deg) translateX(10px);
    width: 100px;
    height: 100px;
  }
`;

export const ContainerText = styled.div`
  position: absolute;
  top: 70px;
  left: 25px;
  width: 100%;

  > h1,
  h3 {
    width: 100%;
  }

  > h3 {
    line-height: 0.2;
    color: #a27dea;
    font-size: 44px;
  }

  > h1 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 95px;
    color: #b92164;
  }

  ${breakpoints.mediaLaptopBetween} {
    top: 65px;
    left: 25px;

    > h1 {
      font-size: 70px;
    }
    > h3 {
      font-size: 28px;
    }
  }
  ${breakpoints.mediaTabletBetween} {
    top: 65px;
    left: 25px;

    > h1 {
      font-size: 72px;
    }
    > h3 {
      font-size: 30px;
    }
  }
  ${breakpoints.phoneL} {
    left: 25px;
    top: 60px;

    > h1 {
      font-size: 56px;
    }
    > h3 {
      font-size: 26px;
    }
  }
`;

export const ContainerDescription = styled.div`
  width: 100%;
  padding: 30px 0 10px;

  > p {
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: normal;
  }
  > p:nth-child(1) {
    color: #e9427c;
    font-weight: bold;
  }
  > p:nth-child(2) span {
    font-weight: bold;
  }
  > p:nth-child(3) {
    margin-top: 20px;

    > span:nth-child(1) {
      color: #e9427c;
      font-weight: bold;
    }
    > span:nth-child(2) {
      color: #ff9700;
      font-weight: bold;
    }
    > span:nth-child(3) {
      color: #ae00ff;
      font-weight: bold;
    }
  }

  ${breakpoints.tabletL} {
    text-align: justify;
    padding: 0 10px;
  }
`;

export const ContainerButton = styled.div`
  width: 100%;
  margin-top: 30px;

  > div {
    border: double 1px transparent;
    border-radius: 5px;
    background-image: linear-gradient(white, white),
      linear-gradient(
        90deg,
        rgba(238, 102, 77, 1) 0%,
        rgba(212, 69, 233, 1) 100%
      );
    background-origin: border-box;
    background-clip: padding-box, border-box;
    width: fit-content;
    padding: 2px;

    > a {
      text-decoration: none;
      background: linear-gradient(
        90deg,
        rgba(238, 102, 77, 1) 0%,
        rgba(212, 69, 233, 1) 100%
      );
      outline: none;
      color: white;
      border: 0;
      padding: 5px 15px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 5px;
      font-size: 18px;
      font-weight: 400;
      cursor: pointer;

      > svg {
        max-width: 30px;
        width: 100%;
      }
    }
  }
`;

export const ContainerHeaderLeft = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

export const ContainerHeaderRight = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: right;

  ${breakpoints.tabletL} {
    justify-content: center;
  }
`;

export const ContainerRight = styled.div`
  width: 450px;
  height: 450px;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  ${breakpoints.mediaLaptopBetween} {
    width: 360px;
    height: 360px;
  }

  ${breakpoints.phoneL} {
    width: 300px;
    height: 300px;
  }
`;
