import styled from "styled-components";
import { breakpoints } from "../../../../constants/breakpoints";

export const ContainerNote = styled.div`
  text-align: center;
  padding: 80px 0 60px;
`;
export const ContainerMainTitle = styled.div`
  position: relative;
  padding: 0px 0 60px;
  width: fit-content;
  margin: auto;
`;

export const RectImg = styled.div`
  position: absolute;
  background: #c6e7ec;
  border-radius: 10px;
  transform: rotate(50deg);
  z-index: -1;
  height: 100px;
  width: 100px;
  left: -20px;
  top: -10px;

  ${breakpoints.tabletM} {
    height: 80px;
    width: 80px;
    left: -20px;
    top: -10px;
  }

  ${breakpoints.phoneL} {
    transform: rotate(50deg) translateX(10px);
    height: 50px;
    width: 50px;
    left: -15px;
    top: -8px;
  }

  ${breakpoints.phoneM} {
    height: 35px;
    width: 35px;
    left: -15px;
    top: -12px;
  }
`;

export const ContainerText = styled.div`
  width: 100%;

  > span {
    font-weight: bold;
    font-size: 4rem;
  }
  > span:nth-child(1) {
    color: #b92164;
  }
  > span:nth-child(2) {
    color: #a27dea;
  }

  ${breakpoints.tabletL} {
    > span {
      font-size: 3.6rem;
    }
  }
  ${breakpoints.tabletM} {
    > span {
      font-size: 3rem;
    }
  }
  ${breakpoints.phoneL} {
    > span {
      font-size: 1.8rem;
    }
  }

  ${breakpoints.phoneM} {
    > span {
      font-size: 1.2rem;
    }
  }
`;

export const ContainerImage = styled.div``;
