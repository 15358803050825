export const breakpoints = {
  laptopL: "@media (min-width: 1200px)",
  laptopM: "@media (max-width: 1199px)",
  laptopS: "@media (max-width: 992px)",
  tabletL: "@media (max-width: 991px)",
  tabletM: "@media (max-width: 768px)",
  tabletS: "@media (max-width: 767px)",
  phoneL: "@media (max-width:  575px)",
  phoneM: "@media (max-width: 360px)",
  phoneS: "@media (max-width: 359px)",
  // MIN
  laptopLMin: "@media (min-width: 1200px)",
  laptopMMin: "@media (min-width: 1199px)",
  laptopSMin: "@media (min-width: 992px)",
  tabletLMin: "@media (min-width: 991px)",
  tabletMMin: "@media (min-width: 768px)",
  tabletSMin: "@media (min-width: 767px)",
  phoneLMin: "@media (min-width: 576px)",
  phoneMMin: "@media (min-width: 360px)",
  phoneSMin: "@media (min-width: 359px)",
  // Values min
  tabletLValue: 1024,
  tabletSValue: 768,
  phoneLValue: 580,
  phoneValue: 480,
  // Between Media Queries
  mediaPCBetween: "@media (min-width: 992px) and (max-width: 1199px)",
  mediaLaptopBetween: "@media (min-width: 768px) and (max-width: 991px)",
  mediaTabletBetween: "@media (min-width: 576px) and (max-width: 767px)",
  mediaPhoneBetween: "@media (min-width: 360px) and (max-width: 575px)",
};
