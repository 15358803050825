import styled from "styled-components";
import { customPalette } from "../../config/theme/theme";
import { breakpoints } from "../../constants/breakpoints";

export const ContainerFooter = styled.div`
  background: ${customPalette.blueColor};
  width: 100%;
  display: grid;
  place-items: center;
  box-sizing: border-box;
  padding: 10px 0;
`;

export const BaseContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  ${breakpoints.tabletL} {
    justify-content: center;
    text-align: center;
  }
`;

export const ContainerLinksSocials = styled(BaseContainer)`
  padding: 10px 20px 10px 0;
  color: white;
  width: 100%;
  gap: 20px;
`;

export const ContainerLinks = styled(BaseContainer)`
  gap: 8px;

  > a {
    border-right: 1px solid white;
    padding-right: 10px;
  }

  > a:nth-child(6) {
    border: 0px;
  }

  ${breakpoints.tabletL} {
    margin: auto;
  }

  ${breakpoints.phoneL} {
    padding: 0 20px;
  }
`;

export const ItemLinkFooter = styled.a`
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  color: white;
  width: 90px;
`;

export const ContainerSocial = styled(BaseContainer)`
  gap: 10px;

  > div img {
    cursor: pointer;
  }

  ${breakpoints.tabletL} {
    margin: auto;
  }
`;

export const ContainerCopyright = styled(BaseContainer)`
  padding: 10px 20px;
  color: white;
  width: 100%;

  ${breakpoints.phoneL} {
    justify-content: center;
  }
`;
