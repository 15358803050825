import React from "react";
import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";

interface IOwnProps {
  children: any;
}
const MainPageLayout: React.FC<IOwnProps> = ({ children }) => {
  return (
    <>
      <Navbar />
      <>{children}</>
      <Footer />
    </>
  );
};

export default MainPageLayout;
