import React from "react";
import { ContainerTitle } from "./title-faq-styles";
import { ArrowIosDownward } from "@styled-icons/evaicons-solid/ArrowIosDownward";
import { Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  getIdViewIcon,
  updateViewIcon,
} from "../../../../../core/store/app-store/appSlice";

interface IOwnProps {
  id: number;
  title: string;
}

const TitleFaq: React.FC<IOwnProps> = ({ id, title }) => {
  const idViewIcon = useAppSelector(getIdViewIcon);
  const dispatch = useAppDispatch();

  return (
    <ContainerTitle
      onClick={() => dispatch(updateViewIcon(id))}
      idIcon={idViewIcon}
      idTitle={id}
    >
      <Typography variant="h6" component="h6">
        {title}
      </Typography>
      {!(idViewIcon == id) && <ArrowIosDownward />}
    </ContainerTitle>
  );
};

export default TitleFaq;
