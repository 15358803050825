import settingsEnv from "./settings-env.json";
import settingsDEV from "./development.json";
import settingsINT from "./integration.json";
import settingsPROD from "./production.json";

let name_APP = "";
let urlBaseProject_APP = "http://localhost";
let environment_APP = "development";
// URLs APIs
let urlCampaigns_API = "http://localhost:8000/api";
let urlOrders_API = "http://localhost:8000/api";
let urlPayment_API = "http://localhost:8000/api";

if (settingsEnv.environment.toUpperCase() == "dev".toUpperCase()) {
  name_APP = settingsDEV.app.name;
  urlBaseProject_APP = settingsDEV.app.urlBase;
  environment_APP = settingsDEV.cloudEnv;
  urlCampaigns_API = settingsDEV.api.campaigns;
  urlOrders_API = settingsDEV.api.orders;
  urlPayment_API = settingsDEV.api.payment;
}

if (settingsEnv.environment.toUpperCase() == "int".toUpperCase()) {
  name_APP = settingsINT.app.name;
  urlBaseProject_APP = settingsINT.app.urlBase;
  environment_APP = settingsINT.cloudEnv;
  urlCampaigns_API = settingsINT.api.campaigns;
  urlOrders_API = settingsINT.api.orders;
  urlPayment_API = settingsINT.api.payment;
}

if (settingsEnv.environment.toUpperCase() == "prod".toUpperCase()) {
  name_APP = settingsPROD.app.name;
  urlBaseProject_APP = settingsPROD.app.urlBase;
  environment_APP = settingsPROD.cloudEnv;
  urlCampaigns_API = settingsPROD.api.campaigns;
  urlOrders_API = settingsPROD.api.orders;
  urlPayment_API = settingsPROD.api.payment;
}

export const settingsAPP = {
  app: {
    name: name_APP,
    urlBase: urlBaseProject_APP,
  },
  api: {
    campaigns: urlCampaigns_API,
    orders: urlOrders_API,
    payment: urlPayment_API,
  },
  environment: environment_APP,
};
