import { Box, Typography } from "@mui/material";
import React from "react";
import Step001IMG from "../../../../assets/img/step_01.png";
import Step002IMG from "../../../../assets/img/step_02.png";
import Step003IMG from "../../../../assets/img/step_03.png";
import Step004IMG from "../../../../assets/img/step_04.png";
import {
  ContainerStep,
  ContainerVideo,
  ContainterStepVideo,
  ItemStep,
  TextTitle,
} from "./step-video-styles";
import ReactPlayer from "react-player";

const StepVideo: React.FC = () => {
  return (
    <ContainterStepVideo id="formas-de-uso">
      <ContainerStep>
        <ItemStep>
          <TextTitle>
            <Typography variant="body2" component="p">
              Yo elijo <p>en la espalda</p>
            </Typography>
          </TextTitle>
          <Box component={"img"} alt="Pasos" src={Step001IMG} />
        </ItemStep>
        <ItemStep>
          <TextTitle>
            <Typography variant="body2" component="p">
              Yo elijo <p>en el abdomen</p>
            </Typography>
          </TextTitle>
          <Box component={"img"} alt="Pasos" src={Step002IMG} />
        </ItemStep>
        <ItemStep>
          <TextTitle>
            <Typography variant="body2" component="p">
              Yo elijo <p>en el brazo</p>
            </Typography>
          </TextTitle>
          <Box component={"img"} alt="Pasos" src={Step003IMG} />
        </ItemStep>
        <ItemStep>
          <TextTitle>
            <Typography variant="body2" component="p">
              Yo elijo <p>en el glúteo</p>
            </Typography>
          </TextTitle>
          <Box component={"img"} alt="Pasos" src={Step004IMG} />
        </ItemStep>
      </ContainerStep>
      <ContainerVideo id="tutorial">
        <ReactPlayer url="https://www.youtube.com/watch?v=vWLIwpkvkJw&t" />
      </ContainerVideo>
    </ContainterStepVideo>
  );
};

export default StepVideo;
