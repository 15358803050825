import React from "react";
import Image01 from "../../../../assets/img/rect_05.png";
import Image02 from "../../../../assets/img/parche.png";
import ParcheIMG from "../../../../assets/img/parche_icon.png";
import {
  ContainerButton,
  ContainerDescription,
  ContainerHeader,
  ContainerImage,
  ContainerImages,
  ContainerLogoText,
  ContainerMainTitle,
  ContainerMoreInfo,
  ContainerText,
  ContainerTextLogo,
  ContainerTitleInfo,
  RectImg,
} from "./section-functionality-styles";
import { Box, Typography } from "@mui/material";
import { ArrowheadRightOutline } from "@styled-icons/evaicons-outline/ArrowheadRightOutline";

const SectionFunctionality = () => {
  return (
    <ContainerHeader>
      <ContainerMainTitle>
        <RectImg />
        <ContainerText>
          <Typography variant="h3" component="span">
            ¿Cómo{" "}
          </Typography>
          <Typography variant="h3" component="span">
            funciona?
          </Typography>
        </ContainerText>
      </ContainerMainTitle>
      <ContainerDescription>
        <Typography variant="caption" component="p">
          <span>La anticoncepción que se pega, </span> es un parche con un
          sistema de anticoncepción hormonal transdérmica de baja dosis, esta
          tecnología permite que la piel, a través de sus poros, absorba de
          manera continua y controlada las hormonas necesarias para inhibir el
          proceso de concepción de forma segura y eficaz.
        </Typography>
      </ContainerDescription>
      <ContainerImages>
        <div>
          <Box
            component={"img"}
            alt="Logo Anticoncepcion que se pega"
            src={Image01}
          />
        </div>
        <div>
          <Box
            component={"img"}
            alt="Logo Anticoncepcion que se pega"
            src={Image02}
          />
        </div>
      </ContainerImages>
      <ContainerMoreInfo>
        <ContainerTitleInfo>
          <ContainerLogoText>
            <ContainerImage>
              <Box
                component={"img"}
                alt="Logo Anticoncepcion que se pega"
                src={ParcheIMG}
              />
            </ContainerImage>
            <ContainerTextLogo>
              <Typography variant="h2" component="h2">
                anticoncepción
              </Typography>
              <Typography variant="h2" component="h2">
                que se <span>pega</span>
              </Typography>
              <Typography
                variant="h5"
                component="h5"
                style={{ color: "#EE665C" }}
              >
                CONSULTA CON TU MÉDICO
              </Typography>
            </ContainerTextLogo>
          </ContainerLogoText>
        </ContainerTitleInfo>
        <ContainerButton>
          <div>
            <a target="_blank" href="https://www.instagram.com/hermanasenhormonas?igsh=MWFmNGVhNGN3cngxaA%3D%3D&utm_source=qr" rel="noreferrer">
              CLICK AQUÍ PARA SABER MÁS
              <ArrowheadRightOutline />
            </a>
          </div>
        </ContainerButton>
      </ContainerMoreInfo>
    </ContainerHeader>
  );
};

export default SectionFunctionality;
