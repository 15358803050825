import React from "react";
import { Outlet } from "react-router-dom";

function App() {
  // window.addEventListener("contextmenu", (e: any) => {
  //   e.preventDefault();
  // });

  window.addEventListener("keydown", (e: any) => {
    if (e.keyCode === 123) {
      e.preventDefault();
    }
  });

  return (
    <>
      <Outlet />
    </>
  );
}

export default App;
