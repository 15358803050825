import styled from "styled-components";
import { breakpoints } from "../../../../../constants/breakpoints";

export const ContainerTitle = styled.div<{ idTitle: number; idIcon: number }>`
  padding: 15px;
  margin: auto;
  color: white;

  > h6 {
    font-weight: bold;
    font-size: 20px;
    color: ${(p) => (p.idIcon == p.idTitle ? "white" : "#b92164")};
  }

  > svg {
    max-width: 100px;
    width: 100%;
  }

  ${breakpoints.tabletL} {
    > h6 {
      font-size: 16px;
    }

    > svg {
      max-width: 80px;
    }
  }

  ${breakpoints.tabletM} {
    max-width: 220px;

    > h6 {
      font-size: 14px;
    }

    > svg {
      max-width: 60px;
    }
  }

  ${breakpoints.tabletM} {
    > h6 {
      font-size: 12px;
    }

    > svg {
      max-width: 40px;
    }
  }
`;
