import { AccordionItem } from "./section-faq";

export const listItems: AccordionItem[] = [
  {
    id: 1,
    open: false,
    title: "¿El sistema es seguro?",
    description:
      "La eficacia del parche anticonceptivo es muy confiable y es comparable al resto de anticonceptivos hormonales.",
  },
  {
    id: 2,
    open: false,
    title: "¿Qué pasa si el parche se despega?",
    description:
      "Si el parche se despega durante menos de 24 horas, ponte uno nuevo inmediatamente y continúa con el ciclo normal",
  },
  {
    id: 3,
    open: false,
    title: "¿Dónde lo puedo comprar?",
    description: "En todas las farmacias del país",
  },
  {
    id: 4,
    open: false,
    title: "¿Le puedo pedir a mi médico que me lo recete?",
    description:
      "Sí, tu médico puede recetarte cualquier método anticonceptivo hormonal incluyendo el parche anticonceptivo.",
  },
  {
    id: 5,
    open: false,
    title: "¿Qué pasa si se me olvida el día de cambio del parche?",
    description:
      "Si no han transcurrido aún 48 horas, mantén el “día de cambio” establecido que tenías.",
  },
  {
    id: 6,
    open: false,
    title: "¿ No le pasa nada con el baño diario?",
    description:
      "Te puedes duchar, bañar, ir al gimnasio o hacer cualquier actividad normal en tu vida diaria porque el parche es eficaz incluso en estas condiciones.",
  },
  {
    id: 7,
    open: false,
    title: "¿Me puedo bañar en la piscina o en la playa con él?",
    description:
      "Sí, el parche está diseñado para realizar todas esas actividades al aire libre sin tener que privarte de ninguna.",
  },
  {
    id: 8,
    open: false,
    title: "¿Es fácil de usar?",
    description:
      "Lo único que tienes que hacer es ponerte un parche nuevo sobre piel limpia y seca y solo se usan 3 por mes.",
  },
] as AccordionItem[];
