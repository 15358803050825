import React from "react";
import { ContainerDescription } from "./item-faq-styles";
import { Typography } from "@mui/material";

interface IOwnProps {
  description: string;
}

const ItemFaq: React.FC<IOwnProps> = ({ description }) => {
  return (
    <ContainerDescription>
      <Typography variant="body2" component="p">
        {description}
      </Typography>
    </ContainerDescription>
  );
};

export default ItemFaq;
