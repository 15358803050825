import { styled } from "styled-components";

export const WrapperMaxSection = styled.div<{ customStyles: string }>`
  max-width: 1200px;
  padding: 20px;
  height: 100%;
  margin: auto;
  width: 100%;

  ${(p) => (p.customStyles != "" ? p.customStyles : "")}
`;
