import styled from "styled-components";
import { breakpoints } from "../../../../../constants/breakpoints";

export const ContainerDescription = styled.div`
  padding: 15px 22px;
  color: white;
  text-align: center;

  ${breakpoints.tabletL} {
    > p {
      font-size: 12px;
    }
  }

  ${breakpoints.tabletM} {
    max-width: 220px;

    > p {
      font-size: 10px;
    }
  }

  ${breakpoints.phoneL} {
    padding: 10px 18px 20px;

    > p {
      font-size: 8px;
    }
  }
`;
