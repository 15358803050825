import styled from "styled-components";
import { breakpoints } from "../../../../constants/breakpoints";

export const ContainerAccordion = styled.div<{
  idIcon: number;
  imgBack: string;
  imgFront: string;
}>`
  padding: 0 0 60px;
  width: 100%;

  > .accordion {
    // justify-content: space-between;
    box-sizing: border-box;
    // align-items: center;
    // flex-wrap: wrap;
    // display: flex;
    // gap: 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;

    ${breakpoints.tabletM} {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  > .accordion > .panel {
    width: 100%;
    height: 300px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    transition: 0.5s;
    gap: 0;

    ${breakpoints.tabletL} {
      height: 260px;
    }

    ${breakpoints.tabletM} {
      height: 250px;
    }
  }

  > .accordion > .panel > .panel__head {
    width: 100%;
    display: flex;
    text-align: center;
    outline: none;
  }
  > .accordion > .panel:nth-child(n) {
    background-image: url("${(p) => p.imgBack}");
    background-repeat: no-repeat;
    background-size: inherit;
    background-position: center;
    // background: linear-gradient(90deg, #f3b2a9 0%, #ea9ce7 100%);
    padding-top: 20px;
  }
  > .accordion > .panel:nth-child(${(p) => p.idIcon}) {
    background-image: url("${(p) => p.imgFront}");
    background-repeat: no-repeat;
    background-size: inherit;
    background-position: center;
    // background: linear-gradient(
    //   90deg,
    //   rgba(238, 102, 77, 1) 0%,
    //   rgba(212, 69, 233, 1) 100%
    // );
    padding-top: 0px;
  }
`;
