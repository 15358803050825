import React from "react";
import {
  ContainerButton,
  ContainerContactus,
  ContainerForm,
  ContainerMainTitle,
  ContainerText,
  ItemInput,
  RectImg,
} from "./contact-us-styles";
import { Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ContactUsSchema,
  ContactUseForm,
} from "../../../../core/models/contact-us-model";
import axios from "axios";
import swal from "sweetalert";


const ContactUs: React.FC = () => {
  const methods = useForm<ContactUseForm>({
    resolver: yupResolver(ContactUsSchema),
    defaultValues: {
      name: "",
      email: "",
      text: "",
    },
  });

  const {
    handleSubmit: submitWrapper,
    formState: { errors },
    register,
  } = methods;

  const handleSubmit = React.useCallback((data: any) => {
    console.log("Data ContacUs => ", data);

    axios({
      url: `https://email-api.anticoncepcionquesepega.com/contact-us/anticonceptivo`,
      method: "POST",
      headers: {
        ContentType: "application/json",
        Accept: "application/json",
        AccessControlAllowOrigin: "*",
        mode: "no-cors"
      },
      data: {
        name: data.name,
        email: data.email,
        text: data.text
      },
    })
      .then((result: any) => {
        swal("Solicitud Enviada!", "Se ha enviado tu solicitud, pronto nos pondremos en contacto contigo!", "success");
      })
      .catch((err) => {
        swal("Error", "No hemos podido enviar tu solicitud, por favor intentalo nuevamente", "error");
      });
      
  }, []);

  React.useEffect(() => {
    console.log("errors -> ", errors);
  }, [errors]);

  return (
    <ContainerContactus id="contacto">
      <ContainerMainTitle>
        <RectImg />
        <ContainerText>
          <Typography variant="h3" component="span">
            Contácta
          </Typography>
          <Typography variant="h3" component="span">
            nos
          </Typography>
        </ContainerText>
      </ContainerMainTitle>
      <ContainerForm>
        <ItemInput>
          <input type="text" placeholder="Nombre" {...register("name")} />
          <Typography variant="caption" component="span">
            {!!(errors.name as any)?.message && (errors.name as any)?.message}
          </Typography>
        </ItemInput>
        <ItemInput>
          <input
            type="text"
            placeholder="Correo electrónico"
            {...register("email")}
          />
          <Typography variant="caption" component="span">
            {!!(errors.email as any)?.message && (errors.email as any)?.message}
          </Typography>
        </ItemInput>
        <ItemInput>
          <textarea placeholder="Asunto" rows={6} {...register("text")} />
          <Typography variant="caption" component="span">
            {!!(errors.text as any)?.message && (errors.text as any)?.message}
          </Typography>
        </ItemInput>
        <ContainerButton>
          <div>
            <button onClick={submitWrapper(handleSubmit)}>Contactar</button>
          </div>
        </ContainerButton>
      </ContainerForm>
    </ContainerContactus>
  );
};

export default ContactUs;
