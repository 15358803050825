import React from "react";
import { styled } from "styled-components";
import { customPalette } from "../../../../config/theme/theme";

export const ButtonNav = styled.a`
  padding: 0.5rem 0.75rem !important;
  text-decoration: none;
  height: fit-content;
  position: relative;
  text-align: center;
  transition: 0.5s;
  cursor: pointer;
  line-height: 2;
  display: block;
  margin: auto;
  color: #fff;
  width: 92%;

  &:hover {
    background: white;
    color: ${customPalette.primaryColor};
  }
`;
