import React from "react";
import {
  ContainerCopyright,
  ContainerFooter,
  ContainerLinks,
  ContainerLinksSocials,
  ContainerSocial,
  ItemLinkFooter,
} from "./footer-styles";
import { Box, Typography } from "@mui/material";
import GedeonLogoIMG from "../../assets/img/gedeon_logo.png";
import InstagramIMG from "../../assets/img/instagram.png";
import FbIMG from "../../assets/img/fb.png";
import YoutubeIMG from "../../assets/img/youtube.png";
import LinkedlnIMG from "../../assets/img/linkedln.png";
import SectionMax from "../section-max/section-max";

const Footer: React.FC = () => {
  return (
    <ContainerFooter>
      <SectionMax>
        <ContainerLinksSocials>
          <ContainerLinks>
            <ItemLinkFooter href="#inicio">Home</ItemLinkFooter>
            <ItemLinkFooter href="#aplicacion">¿Cómo se aplica?</ItemLinkFooter>
            <ItemLinkFooter href="#formas-de-uso">Forma de uso</ItemLinkFooter>
            <ItemLinkFooter href="#tutorial">¿Cómo funciona?</ItemLinkFooter>
            <ItemLinkFooter href="#preguntas-frecuentes">
              Preguntas frecuentes
            </ItemLinkFooter>
            <ItemLinkFooter href="#contacto">Contacto</ItemLinkFooter>
          </ContainerLinks>
          <ContainerSocial>
            <div>
              <Box
                component={"img"}
                alt="Logo Instagram"
                src={InstagramIMG}
                sx={{ maxWidth: "30px" }}
              />
            </div>
            <div>
              <Box
                component={"img"}
                alt="Logo Facebook"
                src={FbIMG}
                sx={{ maxWidth: "30px" }}
              />
            </div>
            <div>
              <Box
                component={"img"}
                alt="Logo Youtube"
                src={YoutubeIMG}
                sx={{ maxWidth: "30px" }}
              />
            </div>
            <div>
              <Box
                component={"img"}
                alt="Logo Linkedln"
                src={LinkedlnIMG}
                sx={{ maxWidth: "30px" }}
              />
            </div>
          </ContainerSocial>
        </ContainerLinksSocials>
        <ContainerCopyright>
          <div>
            <Typography variant="caption" component="span">
              © 2024 Gedeon Richter. Todos los derechos reservados.
            </Typography>
          </div>
          <div>
            <Box
              component={"img"}
              alt="Logo Gedeon"
              src={GedeonLogoIMG}
              sx={{ maxWidth: "200px" }}
            />
          </div>
        </ContainerCopyright>
      </SectionMax>
    </ContainerFooter>
  );
};

export default Footer;
