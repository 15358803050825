import { createTheme } from "@mui/material/styles";

export const customPalette = {
  primaryColor: "#EE664D",
  primaryLightColor: "#FAD3D6",
  secondaryColor: "#D445E9",
  celesteColor: "#C6E7EC",
  blueColor: "#164A95",
  // Alerts color
  successColor: "#A3FEAC",
  infoColor: "#6FA4F2",
  errorColor: "#FF8D87",
  grayLightColor: "#F5F9FF",
  grayDarkColor: "#5E5E5E",
};

export const theme = createTheme({
  palette: {
    primary: {
      main: "#0e1f43",
    },
    secondary: {
      main: "#2974ed",
    },
    success: {
      main: "#93E087",
    },
    info: {
      main: "#87BBE0",
    },
    error: {
      main: "#D96262",
    },
    ...customPalette,
  },
});
