import React from "react";
import HeaderIMG from "../../../../assets/img/rect_01.png";
import {
  ContainerButton,
  ContainerDescription,
  ContainerHeader,
  ContainerHeaderLeft,
  ContainerHeaderRight,
  ContainerMainTitle,
  ContainerRight,
  ContainerText,
  RectImg,
} from "./section-header-styles";
import { Box, Typography } from "@mui/material";
import { ArrowheadRightOutline } from "@styled-icons/evaicons-outline/ArrowheadRightOutline";

const SectionHeader = () => {
  return (
    <ContainerHeader>
      <ContainerHeaderLeft>
        <ContainerMainTitle>
          <RectImg />
          <ContainerText>
            <Typography variant="h3" component="h3">
              En anticoncepción
            </Typography>
            <Typography variant="h1" component="h1">
              Yo elijo
            </Typography>
          </ContainerText>
        </ContainerMainTitle>
        <ContainerDescription>
          <Typography
            variant="body2"
            component="p"
            style={{ paddingBottom: "10px" }}
          >
            ¿Sábes de lo último en métodos de anticoncepción?
          </Typography>
          <Typography variant="caption" component="p">
            El único parche anticonceptivo hormonal de baja dosis y con un
            práctico uso semanal: <span>solo 3 parches al mes!</span>
          </Typography>
          <Typography variant="caption" component="p">
            Elige <span>liberarte</span>, elige tener una{" "}
            <span>nueva actitud</span>, elige un anticonceptivo que{" "}
            <span>simplifica tu vida.</span>
          </Typography>
          <ContainerButton>
            <div>
              <a target="_blank" href="https://www.instagram.com/hermanasenhormonas?igsh=MWFmNGVhNGN3cngxaA%3D%3D&utm_source=qr" rel="noreferrer">
                CLICK AQUÍ PARA SABER MÁS
                <ArrowheadRightOutline />
              </a>
            </div>
          </ContainerButton>
        </ContainerDescription>
      </ContainerHeaderLeft>
      <ContainerHeaderRight>
        <ContainerRight>
          <Box
            component={"img"}
            alt="Logo Anticoncepcion que se pega"
            src={HeaderIMG}
          />
        </ContainerRight>
      </ContainerHeaderRight>
    </ContainerHeader>
  );
};

export default SectionHeader;
