import React from "react";
import {
  ContainerImage,
  ContainerMainTitle,
  ContainerNote,
  ContainerText,
  RectImg,
} from "./section-note-styles";
import { Box, Typography } from "@mui/material";
import Image01 from "../../../../assets/img/rect_04.png";

const SectionNote: React.FC = () => {
  return (
    <ContainerNote>
      <ContainerMainTitle>
        <RectImg />
        <ContainerText>
          <Typography variant="h3" component="span">
            Por{" "}
          </Typography>
          <Typography variant="h3" component="span">
            las dudas...
          </Typography>
        </ContainerText>
      </ContainerMainTitle>
      <ContainerImage>
        <Box
          component={"img"}
          alt="Logo Anticoncepcion que se pega"
          src={Image01}
          sx={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </ContainerImage>
    </ContainerNote>
  );
};

export default SectionNote;
