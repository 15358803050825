import styled from "styled-components";
import { breakpoints } from "../../../../constants/breakpoints";

export const ContainerInfo = styled.div``;

export const ContainerSteps = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 0;
  gap: 20px;
  flex-wrap: wrap;

  ${breakpoints.tabletL} {
    gap: 20px;
  }

  ${breakpoints.phoneL} {
    justify-content: center;
  }
`;

export const ItemStep = styled.div`
  background: linear-gradient(
    90deg,
    rgba(238, 102, 77, 1) 0%,
    rgba(212, 69, 233, 1) 100%
  );
  box-sizing: border-box;
  height: 280px;
  width: 280px;
  border-radius: 50%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  flex-direction: column;

  > img {
    object-fit: contain;
    max-width: 130px;
    width: 100%;
    margin-bottom: 20px;

    ${breakpoints.tabletL} {
      max-width: 80px;
    }

    ${breakpoints.tabletM} {
      max-width: 40px;
    }

    ${breakpoints.phoneL} {
      max-width: 40px;
    }
  }

  > p {
    text-align: center;
    margin: auto;
    color: white;
    margin: 0;
    max-width: 140px;

    ${breakpoints.tabletM} {
      font-size: 9px;
    }

    ${breakpoints.phoneL} {
      font-size: 7px;
    }
  }

  ${breakpoints.tabletL} {
    height: 220px;
    width: 220px;
  }

  ${breakpoints.tabletM} {
    height: 150px;
    width: 150px;
  }

  ${breakpoints.phoneL} {
    height: 120px;
    width: 120px;
  }
`;

export const ContainerLogoText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerTitleInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;

  > div h5 {
    font-size: 1.4rem;
    padding-bottom: 30px;
  }

  ${breakpoints.tabletL} {
    gap: 20px;

    > div h5 {
      font-size: 1.2rem;
      padding-bottom: 5px;
    }
  }

  ${breakpoints.tabletM} {
    justify-content: center;
    gap: 20px;

    > div h5 {
      font-size: 1.2rem;
      padding-bottom: 40px;
    }
  }

  ${breakpoints.phoneL} {
    > div h5 {
      font-size: 1.2rem;
      padding-bottom: 0px;
    }
  }
`;

export const ContainerTextLogo = styled.div`
  > h2 {
    margin: 0;
    padding: 0;
    color: #b92164;
    font-weight: bold;
    line-height: 0.8;
    font-size: 4rem;

    > span {
      color: #a27dea;
    }

    ${breakpoints.tabletL} {
      font-size: 3rem;
    }

    ${breakpoints.tabletM} {
      font-size: 2.6rem;
    }

    ${breakpoints.phoneL} {
      font-size: 2rem;
    }
  }
`;

export const ContainerImage = styled.div`
  width: 150px;
  height: 100%;

  > img {
    max-width: 140px;
  }

  ${breakpoints.tabletL} {
    width: 90px;
    height: 100%;

    > img {
      max-width: 80px;
    }
  }

  ${breakpoints.phoneL} {
    width: 65px;

    > img {
      max-width: 60px;
    }
  }
`;
