import React from "react";
import { createBrowserRouter } from "react-router-dom";
import MainPage from "../../features/main-page/main-page";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
  },
]);
