import styled from "styled-components";
import { breakpoints } from "../../../../constants/breakpoints";

export const ContainerContactus = styled.div`
  text-align: center;
  padding: 60px 0 60px;
`;
export const ContainerMainTitle = styled.div`
  position: relative;
  padding: 0px 0 60px;
  width: fit-content;
  margin: auto;
`;
export const RectImg = styled.div`
  position: absolute;
  background: #c6e7ec;
  border-radius: 10px;
  transform: rotate(50deg);
  z-index: -1;
  height: 100px;
  width: 100px;
  left: -20px;
  top: -10px;

  ${breakpoints.tabletM} {
    height: 80px;
    width: 80px;
    left: -20px;
    top: -10px;
  }

  ${breakpoints.phoneL} {
    transform: rotate(50deg) translateX(10px);
    height: 50px;
    width: 50px;
    left: -15px;
    top: -8px;
  }

  ${breakpoints.phoneM} {
    height: 35px;
    width: 35px;
    left: -15px;
    top: -12px;
  }
`;

export const ContainerText = styled.div`
  width: 100%;

  > span {
    font-weight: bold;
    font-size: 4rem;
  }
  > span:nth-child(1) {
    color: #b92164;
  }
  > span:nth-child(2) {
    color: #a27dea;
  }

  ${breakpoints.tabletL} {
    > span {
      font-size: 3.6rem;
    }
  }
  ${breakpoints.tabletM} {
    > span {
      font-size: 3rem;
    }
  }
  ${breakpoints.phoneL} {
    > span {
      font-size: 1.8rem;
    }
  }

  ${breakpoints.phoneM} {
    > span {
      font-size: 1.2rem;
    }
  }
`;

export const ContainerForm = styled.div`
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  width: 100%;
  gap: 20px;
`;

export const ItemInput = styled.div`
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  margin: auto;
  width: 100%;

  > span {
    width: 50%;
    text-align: left;
    color: red;
    margin-top: 5px;
  }

  > input,
  textarea {
    border: double 1px transparent;
    border-radius: 5px;
    background-image: linear-gradient(white, white),
      linear-gradient(
        90deg,
        rgba(238, 102, 77, 1) 0%,
        rgba(212, 69, 233, 1) 100%
      );
    background-origin: border-box;
    background-clip: padding-box, border-box;
    padding: 10px;
    outline: none;
    resize: none;
    width: 50%;

    ${breakpoints.tabletM} {
      width: 100%;
    }
  }
`;

export const ContainerButton = styled.div`
  width: 50%;
  margin: auto;
  padding: 20px 0 0;

  > div {
    border: double 1px transparent;
    border-radius: 5px;
    background-image: linear-gradient(white, white),
      linear-gradient(
        90deg,
        rgba(238, 102, 77, 1) 0%,
        rgba(212, 69, 233, 1) 100%
      );
    background-origin: border-box;
    background-clip: padding-box, border-box;
    width: 100%;
    padding: 2px;

    > button {
      width: 100%;
      background: linear-gradient(
        90deg,
        rgba(238, 102, 77, 1) 0%,
        rgba(212, 69, 233, 1) 100%
      );
      outline: none;
      color: white;
      border: 0;
      padding: 10px 15px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 5px;
      font-size: 30px;
      font-weight: 400;
      cursor: pointer;

      > svg {
        max-width: 30px;
        width: 100%;
      }

      ${breakpoints.tabletL} {
        font-size: 25px;
      }

      ${breakpoints.phoneL} {
        font-size: 18px;
      }
    }
  }

  ${breakpoints.tabletM} {
    width: 100%;
  }
`;
