import styled from "styled-components";
import { breakpoints } from "../../../../constants/breakpoints";

export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
  padding: 0;

  ${breakpoints.tabletL} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 40px;
  }

  ${breakpoints.phoneL} {
    row-gap: 20px;
  }
`;

export const ContainerMainTitle = styled.div`
  position: relative;
  padding: 60px 0 20px;
  width: fit-content;
`;

export const RectImg = styled.div`
  position: absolute;
  background: #c6e7ec;
  border-radius: 10px;
  transform: rotate(50deg);
  z-index: -1;
  height: 100px;
  width: 100px;
  left: -20px;
  top: 50px;

  ${breakpoints.tabletM} {
    height: 80px;
    width: 80px;
    left: -20px;
    top: 50px;
  }

  ${breakpoints.phoneL} {
    transform: rotate(50deg) translateX(10px);
    height: 50px;
    width: 50px;
    left: -15px;
    top: 45px;
  }

  ${breakpoints.phoneM} {
    height: 35px;
    width: 35px;
    left: -15px;
    top: -12px;
  }
`;

export const ContainerText = styled.div`
  width: 100%;

  > span {
    font-weight: bold;
    font-size: 4rem;
  }
  > span:nth-child(1) {
    color: #b92164;
  }
  > span:nth-child(2) {
    color: #a27dea;
  }

  ${breakpoints.tabletL} {
    > span {
      font-size: 3.6rem;
    }
  }
  ${breakpoints.tabletM} {
    > span {
      font-size: 3rem;
    }
  }
  ${breakpoints.phoneL} {
    > span {
      font-size: 1.8rem;
    }
  }

  ${breakpoints.phoneM} {
    > span {
      font-size: 1.2rem;
    }
  }
`;

export const ContainerDescription = styled.div`
  width: 100%;
  padding: 30px 0;

  > p {
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: normal;
    font-size: 22px;

    > span {
      color: #ad00fe;
      font-weight: bold;
    }
  }

  ${breakpoints.tabletL} {
    text-align: justify;
    padding: 0 30px;
  }

  ${breakpoints.phoneL} {
    padding: 10px 10px 20px;
  }
`;

export const ContainerButton = styled.div`
  width: 50%;

  > div {
    border: double 1px transparent;
    border-radius: 5px;
    background-image: linear-gradient(white, white),
      linear-gradient(
        90deg,
        rgba(238, 102, 77, 1) 0%,
        rgba(212, 69, 233, 1) 100%
      );
    background-origin: border-box;
    background-clip: padding-box, border-box;
    width: 100%;
    padding: 2px;

    > a {
      width: 100%;
      text-decoration: none;
      background: linear-gradient(
        90deg,
        rgba(238, 102, 77, 1) 0%,
        rgba(212, 69, 233, 1) 100%
      );
      outline: none;
      color: white;
      border: 0;
      padding: 10px 15px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 5px;
      font-size: 30px;
      font-weight: 400;
      cursor: pointer;

      > svg {
        max-width: 30px;
        width: 100%;
      }

      ${breakpoints.tabletL} {
        font-size: 25px;
      }

      ${breakpoints.phoneL} {
        font-size: 18px;
      }
    }
  }

  ${breakpoints.tabletL} {
    width: 100%;
  }
`;

export const ContainerHeaderLeft = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

export const ContainerHeaderRight = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerRight = styled.div`
  width: 350px;
  height: 350px;
  background: #bed9c5;
  border-radius: 50%;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  ${breakpoints.phoneL} {
    width: 280px;
    height: 280px;
  }
`;

export const ContainerImages = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px 0 60px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 40px;

  > div {
    width: 50%;
  }

  > div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  > div:nth-child(1) img {
    width: 90%;
  }

  > div:nth-child(2) img {
    width: 90%;
  }

  ${breakpoints.tabletL} {
    > img:nth-child(1) {
      width: 300px;
    }

    > img:nth-child(2) {
      width: 260px;
    }
  }

  ${breakpoints.tabletS} {
    > img:nth-child(1) {
      width: 250px;
    }

    > img:nth-child(2) {
      width: 200px;
    }
  }

  ${breakpoints.phoneL} {
    gap: 20px;

    > img:nth-child(1) {
      width: 180px;
    }

    > img:nth-child(2) {
      width: 140px;
    }
  }

  ${breakpoints.phoneM} {
    gap: 20px;

    > img:nth-child(1) {
      width: 140px;
    }

    > img:nth-child(2) {
      width: 100px;
    }
  }
`;

export const ContainerMoreInfo = styled.div`
  justify-content: space-between;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  display: flex;
  width: 100%;

  ${breakpoints.tabletL} {
    gap: 50px;
  }
`;

export const ContainerTitleInfo = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;

  ${breakpoints.tabletL} {
    justify-content: flex-start;
    width: 100%;
  }
`;

export const ContainerTextLogo = styled.div`
  position: relative;
  left: 40px;
  top: 8px;

  > h2 {
    width: fit-content;
    color: #b92164;
    font-weight: bold;
    line-height: 0.8;
    font-size: 3.8rem;

    > span {
      color: #a27dea;
    }
  }
  > h5 {
    margin-top: 15px;
    margin-left: 50px;
    font-size: 28px;
    font-size: 1.7rem;
  }

  ${breakpoints.tabletL} {
    > h2 {
      font-size: 3.2rem;
    }

    > h5 {
      font-size: 1.2rem;
    }
  }

  ${breakpoints.tabletM} {
    > h2 {
      font-size: 2.4rem;
    }

    > h5 {
      font-size: 1rem;
    }
  }

  ${breakpoints.phoneL} {
    h2 {
      font-size: 2.4rem;
    }

    > h5 {
      font-size: 1rem;
    }
  }
`;

export const ContainerImage = styled.div`
  position: absolute;
  width: 120px;
  height: 100%;
  left: 0;
  top: -10px;

  > img {
    height: 140px;
  }

  ${breakpoints.tabletM} {
    width: 100px;

    > img {
      height: 100px;
    }
  }

  ${breakpoints.phoneL} {
    width: 100px;

    > img {
      height: 100px;
    }
  }
`;

export const ContainerLogoText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
