import React from "react";
import Step001 from "../../../../assets/img/step_001.svg";
import Step002 from "../../../../assets/img/step_002.svg";
import Step003 from "../../../../assets/img/step_003.svg";
import { Box, Typography } from "@mui/material";
import {
  ContainerImage,
  ContainerInfo,
  ContainerLogoText,
  ContainerSteps,
  ContainerTextLogo,
  ContainerTitleInfo,
  ItemStep,
} from "./section-info-step-styles";
import ParcheIMG from "../../../../assets/img/parche_icon.png";

const SectionInfoStep = () => {
  return (
    <ContainerInfo>
      <ContainerTitleInfo>
        <ContainerLogoText>
          <ContainerImage>
            <Box
              component={"img"}
              alt="Logo Anticoncepcion que se pega"
              src={ParcheIMG}
            />
          </ContainerImage>
          <ContainerTextLogo>
            <Typography variant="h2" component="h2">
              anticoncepción
            </Typography>
            <Typography variant="h2" component="h2">
              que se <span>pega</span>
            </Typography>
          </ContainerTextLogo>
        </ContainerLogoText>
        <div>
          <Typography variant="h5" component="h5" style={{ color: "#EE665C" }}>
            CONSULTA CON TU MÉDICO
          </Typography>
        </div>
      </ContainerTitleInfo>
      <ContainerSteps id="aplicacion">
        <ItemStep>
          <Box component={"img"} alt="Imagen pasos" src={Step001} />
          <Typography variant="caption" component="p">
            Con un toque se adhiere a tu piel
          </Typography>
        </ItemStep>
        <ItemStep>
          <Box component={"img"} alt="Imagen pasos" src={Step002} />
          <Typography variant="caption" component="p">
            Sólo 3 parches al mes, la cuarta semana descansas
          </Typography>
        </ItemStep>
        <ItemStep>
          <Box component={"img"} alt="Logo Imagen pasos" src={Step003} />
          <Typography variant="caption" component="p">
            Olvídate del estrés del día a día
          </Typography>
        </ItemStep>
      </ContainerSteps>
    </ContainerInfo>
  );
};

export default SectionInfoStep;
