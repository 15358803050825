import React from "react";
import { WrapperMaxSection } from "./section-max-styles";

interface IOwnProps {
  customStyles?: string;
  children: any;
}

const SectionMax: React.FC<IOwnProps> = ({ children, customStyles = "" }) => {
  return (
    <WrapperMaxSection customStyles={customStyles}>
      {children}
    </WrapperMaxSection>
  );
};

export default SectionMax;
