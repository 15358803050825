import React from "react";
import HeaderIMG from "../../../../assets/img/rect_02.png";
import {
  ContainerButton,
  ContainerDescription,
  ContainerHeader,
  ContainerHeaderLeft,
  ContainerHeaderRight,
  ContainerMainTitle,
  ContainerRight,
  ContainerText,
  RectImg,
} from "./section-info-styles";
import { Box, Typography } from "@mui/material";
import { ArrowheadRightOutline } from "@styled-icons/evaicons-outline/ArrowheadRightOutline";

const SectionInfo = () => {
  return (
    <ContainerHeader>
      <ContainerHeaderLeft>
        <ContainerMainTitle>
          <RectImg />
          <ContainerText>
            <Typography variant="h2" component="span">
              Yo elijo{" "}
            </Typography>
            <Typography variant="h2" component="span">
              comodidad
            </Typography>
          </ContainerText>
        </ContainerMainTitle>
        <ContainerDescription>
          <Typography variant="caption" component="p">
            El anticonceptivo que se pega puedes elegir colocarlo en diferentes
            partes de tu cuerpo e ir rotando en el tiempo, su sistema de
            adherencia es 100% seguro, de fácil manipulación y súper cómodo.
          </Typography>
          <ContainerButton>
            <div>
              <a target="_blank" href="https://www.instagram.com/hermanasenhormonas?igsh=MWFmNGVhNGN3cngxaA%3D%3D&utm_source=qr" rel="noreferrer">
                CLICK AQUÍ PARA SABER MÁS
                <ArrowheadRightOutline />
              </a>
            </div>
          </ContainerButton>
        </ContainerDescription>
      </ContainerHeaderLeft>
      <ContainerHeaderRight>
        <ContainerRight>
          <Box
            component={"img"}
            alt="Logo Anticoncepcion que se pega"
            src={HeaderIMG}
          />
        </ContainerRight>
      </ContainerHeaderRight>
    </ContainerHeader>
  );
};

export default SectionInfo;
