import React from "react";
import Image01 from "../../../../assets/img/rect_03.png";
import CalendarIMG from "../../../../assets/img/calendar.svg";
import ParcheIMG from "../../../../assets/img/parche_icon.png";
import {
  ContainerButton,
  ContainerDescription,
  ContainerHeader,
  ContainerImage,
  ContainerImages,
  ContainerLogoText,
  ContainerMainTitle,
  ContainerMoreInfo,
  ContainerText,
  ContainerTextLogo,
  ContainerTitleInfo,
  RectImg,
} from "./section-other-info-styles";
import { Box, Typography } from "@mui/material";
import { ArrowheadRightOutline } from "@styled-icons/evaicons-outline/ArrowheadRightOutline";

const SectionOtherInfo = () => {
  return (
    <ContainerHeader>
      <ContainerMainTitle>
        <RectImg />
        <ContainerText>
          <Typography variant="h2" component="span">
            Solo 3{" "}
          </Typography>
          <Typography variant="h2" component="span">
            parches al mes
          </Typography>
        </ContainerText>
      </ContainerMainTitle>
      <ContainerImages>
        <div>
          <Box
            component={"img"}
            alt="Logo Anticoncepcion que se pega"
            src={Image01}
          />
        </div>
        <div>
          <Box
            component={"img"}
            alt="Logo Anticoncepcion que se pega"
            src={CalendarIMG}
          />
        </div>
      </ContainerImages>
      <ContainerDescription>
        <Typography variant="caption" component="p">
          <span>La anticoncepción que se pega</span> es el único con un práctico
          uso semanal donde sólo usas 3 parches al mes y te olvidas de
          preocuparte. Hacemos muchas cosas en el día y a veces nos olvidamos de
          la mitad.
        </Typography>
      </ContainerDescription>
      <ContainerMoreInfo>
        <ContainerTitleInfo>
          <ContainerLogoText>
            <ContainerImage>
              <Box
                component={"img"}
                alt="Logo Anticoncepcion que se pega"
                src={ParcheIMG}
              />
            </ContainerImage>
            <ContainerTextLogo>
              <Typography variant="h2" component="h2">
                anticoncepción
              </Typography>
              <Typography variant="h2" component="h2">
                que se <span>pega</span>
              </Typography>
              <Typography
                variant="h5"
                component="h5"
                style={{ color: "#EE665C" }}
              >
                CONSULTA CON TU MÉDICO
              </Typography>
            </ContainerTextLogo>
          </ContainerLogoText>
        </ContainerTitleInfo>
        <ContainerButton>
          <div>
            <a target="_blank" href="https://www.instagram.com/hermanasenhormonas?igsh=MWFmNGVhNGN3cngxaA%3D%3D&utm_source=qr" rel="noreferrer">
              CLICK AQUÍ PARA SABER MÁS
              <ArrowheadRightOutline />
            </a>
          </div>
        </ContainerButton>
      </ContainerMoreInfo>
    </ContainerHeader>
  );
};

export default SectionOtherInfo;
