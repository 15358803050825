import React from "react";
import {
  BottomNavbarMobile,
  ContainerBar,
  ContainerFixedNav,
  ContainerGridNav,
  ContainerLogo,
  ContainerNavLinks,
} from "./navbar-styles";
import ParchesLogoIMG from "../../assets/img/logo.png";
import { Box } from "@mui/material";
import SectionMax from "../section-max/section-max";
import ItemNav from "./components/item-nav/item-nav";
import { Bars } from "@styled-icons/fa-solid/Bars";
import ItemNavMobile from "./components/item-nav-mobile/item-nav-mobile";
import { settingsAPP } from "../../config/environments/settings";
import { breakpoints } from "../../constants/breakpoints";
import { useAppDispatch } from "../../app/hooks";
import axios from "axios";
import { Link } from "react-router-dom";

const Navbar: React.FC = () => {
  const [isActiveMenu, setIsActiveMenu] = React.useState(false);
  const dispatch = useAppDispatch();

  const handleOpenMenu = () => setIsActiveMenu(!isActiveMenu);

  React.useEffect(() => {
    axios({
      url: `${settingsAPP.api.campaigns}/terms-and-conditions`,
      method: "GET",
      headers: {
        ContentType: "application/json",
        Accept: "application/json",
      },
    })
      .then((result: any) => {
        const data = result.data as any;
        if (data) {
          // dispatch(updateTermsAndConditions(data.message));
        }
      })
      .catch((err: any) => {
        console.log("Error -> ", err);
      });
    getDataSettings();
  }, []);

  const getDataSettings = () => {
    axios({
      url: `${settingsAPP.api.campaigns}/configuracion/agradecimiento`,
      method: "GET",
      headers: {
        ContentType: "application/json",
        Accept: "application/json",
      },
    })
      .then((result: any) => {
        const data = result.data as any;
        if (data) {
          // dispatch(updateGratitudeSettings(data));
        }
      })
      .catch((err: any) => {
        console.log("Error -> ", err);
      });
  };

  return (
    <>
      <ContainerFixedNav id="inicio">
        <SectionMax
          customStyles={`
            ${breakpoints.laptopL}{
              padding-right: 0px;
              padding-left: 0px;
            }
          `}
        >
          <ContainerGridNav>
            <ContainerLogo>
              <Box
                component="img"
                alt="Logo AnticonceptivoQueSePega"
                src={ParchesLogoIMG}
                sx={{
                  maxHeight: "100px",
                  transition: "0.5s",
                }}
              />
            </ContainerLogo>
            <ContainerNavLinks>
              <ItemNav text="Home" to="inicio" />
              <ItemNav text="¿Cómo se aplica?" to="aplicacion" />
              <ItemNav text="Forma de uso" to="formas-de-uso" />
              <ItemNav text="¿Cómo funciona?" to="tutorial" />
              <ItemNav text="Preguntas frecuentes" to="preguntas-frecuentes" />
              <ItemNav text="Contacto" to="contacto" />
            </ContainerNavLinks>
            <ContainerBar onClick={handleOpenMenu}>
              <Bars />
            </ContainerBar>
          </ContainerGridNav>
        </SectionMax>
        <BottomNavbarMobile isActive={isActiveMenu}>
          <ItemNavMobile text="Home" to="inicio" />
          <ItemNavMobile text="¿Cómo se aplica?" to="aplicacion" />
          <ItemNavMobile text="Forma de uso" to="formas-de-uso" />
          <ItemNavMobile text="¿Cómo funciona?" to="tutorial" />
          <ItemNavMobile
            text="Preguntas frecuentes"
            to="preguntas-frecuentes"
          />
          <ItemNavMobile text="Contacto" to="contacto" />
        </BottomNavbarMobile>
      </ContainerFixedNav>
    </>
  );
};

export default Navbar;
